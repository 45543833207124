<template>
    <div class="regoin">
        <div>
            <span class="bules">注册账户</span> &nbsp;
            <span class="login_d">已有账户？去 <router-link :to="{name:'logins'}" class="regHref">登录</router-link></span>
        </div>
        <ul class="account_ul">
            <li class="prompt">
                    <div class="prompt_div">
                        <span>请输入密码</span>
                    </div>
                </li>
            <li class="bag_aside_item clearfix">
                <input type="text" class="item_input" placeholder="请输入手机号">
            </li>
            <li class="bag_aside_item clearfix">
                <div class="item_div_l fl">
                    <input type="text" class="item_input" placeholder="请输入验证码">
                </div>
                <div class="item_div_r fr">
                    <el-button type="primary" plain>发送验证码</el-button>
                </div>
            </li>
            <li class="bag_aside_item clearfix">
                <input type="password" class="item_input" placeholder="请输入密码">
            </li>
            <li class="bag_aside_item clearfix">
                <input type="password" class="item_input" placeholder="请确认密码">
            </li>
            <li class="bag_aside_item clearfix">
                <button class="item_but">注册</button>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    data() {
        return {
            
        }
    },
    methods: {
        
    },
    mounted() {
        
    },
}
</script>
<style scoped>
@import '../css/login1.css';
</style>